import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import Section from "../../../components/Section";
import { useTranslation } from "react-i18next";
import AwardSection from "../../../components/Section/AwardSection";
import { dialysisContentDatas } from "./dialysisContents";
import FeaturesSectionStyle2 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle2";
import FeaturesSectionStyle3 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle3";
import List from "../../../components/List";
import TwoGridAccordion from "../../../components/TwoGridAccordion";
import AboutSectionStyle6 from "../../../components/Section/AboutSection/AboutSectionStyle6";
import SectionHeading from "../../../components/SectionHeading";
import Spacing from "../../../components/Spacing";
import ScrollToSection from "../../../components/ScrollToSection";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";

const Dialysis = () => {
  pageTitle("Dialysis");
  const { t } = useTranslation(["dialysis", "common"]);

  const dialysisNeededRef = useRef(null);
  const typeOfDialysisRef = useRef(null);
  const chooseDialysisRef = useRef(null);
  const livingDialysisRef = useRef(null);
  const treatmentRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "What is Dialysis?",
      btnRef: dialysisNeededRef,
      btnImage: "/images/kidney_educations/dialysis/dialysis-icon-01.svg",
    },
    {
      btnName: "Types of dialysis",
      btnRef: typeOfDialysisRef,
      btnImage: "/images/kidney_educations/dialysis/dialysis-icon-02.svg",
    },
    {
      btnName: "Choose right dialysis",
      btnRef: chooseDialysisRef,
      btnImage: "/images/kidney_educations/dialysis/dialysis-icon-03.svg",
    },
    {
      btnName: "Living with dialysis",
      btnRef: livingDialysisRef,
      btnImage: "/images/kidney_educations/dialysis/dialysis-icon-04.svg",
    },
    {
      btnName: "Treatment",
      btnRef: treatmentRef,
      btnImage: "/images/kidney_educations/dialysis/dialysis-icon-05.svg",
    },
  ];

  const {
    whyDialysisIsNeededData,
    typesOfDialysisData1,
    typesOfDialysisData2,
    choosingDialysisOptionData,
    livingWithDialysisData,
  } = dialysisContentDatas();

  const typesOfDialysis1 = [
    {
      title: `${t("typesOfDialysisLists.list1.title")}`,
      content: (
        <List
          heading=""
          description={`${t("typesOfDialysisLists.list1.description")}:`}
          data={typesOfDialysisData1}
        />
      ),
    },
  ];

  const typesOfDialysis2 = [
    {
      title: `${t("typesOfDialysisLists.list2.title")}`,
      content: (
        <List
          heading=""
          description={`${t("typesOfDialysisLists.list2.description")}:`}
          data={typesOfDialysisData2}
        />
      ),
    },
  ];
  const { blogData } = commonConstants();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/dialysis/dialysis-web-banner-new.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* Dialysis Needed Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_dialysis cs_dialysis_need"
        sectionRef={dialysisNeededRef}
      >
        <AwardSection
          sectionTitle={t("whyDialysisIsNeeded.title")}
          sectionSubTitle={t("whyDialysisIsNeeded.description1")}
          sectionSubTitle2={`${t("whyDialysisIsNeeded.description2")}:`}
          data={whyDialysisIsNeededData}
        />
      </Section>
      {/* Dialysis Needed Section End */}

      {/* Types Of Dialysis Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_dialysis"
        sectionRef={typeOfDialysisRef}
      >
        <div className="container">
          <SectionHeading
            title={t("typesOfDialysis.title")}
            subTitle={`${t("typesOfDialysis.description")}:`}
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="30" />
        </div>
        <TwoGridAccordion
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          list1={typesOfDialysis1}
          list2={typesOfDialysis2}
          customClasses="cs_accordians_kidney_education"
        />
      </Section>
      {/* Types Of Dialysis Section End */}

      {/* Choose Right Dialysis Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_dialysis"
        sectionRef={chooseDialysisRef}
      >
        <FeaturesSectionStyle2
          sectionTitle={t("choosingDialysisOption.title")}
          sectoinSubTitle={`${t("choosingDialysisOption.description")}:`}
          imgUrl="/images/kidney_educations/dialysis/right-dialysis-new.jpg"
          data={choosingDialysisOptionData}
        />
      </Section>
      {/* Choose Right Dialysis Section End */}

      {/* Living With Dialysis Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_dialysis"
        sectionRef={livingDialysisRef}
      >
        <FeaturesSectionStyle3
          sectionTitle={t("livingWithDialysis.title")}
          subTitle={`${t("livingWithDialysis.description")}:`}
          data={livingWithDialysisData}
        />
      </Section>
      {/* Living With Dialysis Section End */}

      {/* Treatment Banner Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_dialysis cs_blog_details"
        sectionRef={treatmentRef}
      >
        <AboutSectionStyle6
          title=""
          subTitle={t("treatmentForTheseDisorders")}
          imgUrl="/images/home/patient-recovery-dialysis-page-bottom.jpg"
          quoteBgColor="#f79226"
        />
      </Section>
      {/* Treatment Banner Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default Dialysis;
