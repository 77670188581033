import React, { useRef } from "react";
import Section from "../../../components/Section";
import { pageTitle } from "../../../helpers/PageTitle";
import { useTranslation } from "react-i18next";
import { kidneyBiopsyContentDatas } from "./kidneyBiopsyContents";
import FeaturesSectionStyle2 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle2";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import AboutSectionStyle3 from "../../../components/Section/AboutSection/AboutSectionStyle3";
import AboutSectionStyle5 from "../../../components/Section/AboutSection/AboutSectionStyle5";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SectionHeading from "../../../components/SectionHeading";
import Spacing from "../../../components/Spacing";
import TwoGridAccordion from "../../../components/TwoGridAccordion";
import List from "../../../components/List";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";

const KidneyBiopsy = () => {
  pageTitle("Kidney Biopsy");
  const { t } = useTranslation(["kidneyBiopsy", "common"]);

  const kidneyBiopsyRef = useRef(null);
  const typesOfBiopsiesRef = useRef(null);
  const afterBiopsyRef = useRef(null);
  const recoveringFromKidneyBiopsyRef = useRef(null);
  const importantNoteRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Kidney biopsy",
      btnRef: kidneyBiopsyRef,
      btnImage:
        "/images/kidney_educations/kidney-biopsy/kidney-biopsy-icon-01.svg",
    },
    {
      btnName: "Types of biopsies",
      btnRef: typesOfBiopsiesRef,
      btnImage:
        "/images/kidney_educations/kidney-biopsy/kidney-biopsy-icon-02.svg",
    },
    {
      btnName: "After Biopsy Care",
      btnRef: afterBiopsyRef,
      btnImage:
        "/images/kidney_educations/kidney-biopsy/kidney-biopsy-icon-03.svg",
    },
    {
      btnName: "Recovering from a kidney biopsy",
      btnRef: recoveringFromKidneyBiopsyRef,
      btnImage:
        "/images/kidney_educations/kidney-biopsy/kidney-biopsy-icon-04.svg",
    },
    // {
    //   btnName: "Important Note",
    //   btnRef: importantNoteRef,
    // },
  ];

  const { whyKidneyBiopsyPerformedData, typesOfKidneyBiopsiesData1 } =
    kidneyBiopsyContentDatas();

  const typesOfBiopsies1 = [
    {
      title: `${t("typesOfKidneyBiopsiesLists.list1.title")}`,
      content: (
        <List
          heading=""
          description={`${t("typesOfKidneyBiopsiesLists.list1.description")}:`}
          data={typesOfKidneyBiopsiesData1}
        />
      ),
    },
  ];

  const typesOfBiopsies2 = [
    {
      title: `${t("typesOfKidneyBiopsiesLists.list2.title")}`,
      content: (
        <List
          heading=""
          description={`${t("typesOfKidneyBiopsiesLists.list2.description")}:`}
          data={[]}
        />
      ),
    },
  ];


  const { blogData } = commonConstants();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/kidney-biopsy/kidney-biopsy-web-banner-new.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* <Section className="cs_kidney_biopsy">
        <BannerSectionStyle3
          bgUrl="/images/appointments/banner_bg.svg"
          imgUrl="/images/appointments/banner_img.png"
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass="cs_kidney_education_color"
        />
      </Section> */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_white_bg"
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* Kidney Biopsy Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={kidneyBiopsyRef}
        className="cs_white_bg cs_kidney_biopsy"
      >
        <FeaturesSectionStyle2
          sectionTitle={t("whyKidneyBiopsyPerformed.title")}
          sectoinSubTitle={`${t("whyKidneyBiopsyPerformed.description")}:`}
          // imgUrl="/images/about/why_choose_us.jpeg"
          imgUrl="/images/kidney_educations/kidney-biopsy/kidney-biopsy-perform.jpg"
          data={whyKidneyBiopsyPerformedData}
        />
      </Section>
      {/* Kidney Biopsy Section End */}

      {/* Types Of Dialysis Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={typesOfBiopsiesRef}
        className="cs_dialysis cs_white_bg"
      >
        <div className="container">
          <SectionHeading
            title={t("typesOfKidneyBiopsies.title")}
            subTitle={`${t("typesOfKidneyBiopsies.description")}:`}
            variantColor="cs_white_color"
          />
          <Spacing lg="30" md="30" />
        </div>
        <TwoGridAccordion
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          list1={typesOfBiopsies1}
          list2={typesOfBiopsies2}
          customClasses="cs_accordians_kidney_education"
        />
      </Section>
      {/* Types Of Dialysis Section End */}

      {/* After Biopsy Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={afterBiopsyRef}
        className="cs_white_bg cs_kidney_biopsy"
      >
        <AboutSectionStyle5
          imgUrl="/images/kidney_educations/kidney-biopsy/after-biopsy.jpg"
          title={t("whatExpectAfterBiopsy.title")}
          subTitle={t("whatExpectAfterBiopsy.description")}
        />
      </Section>
      {/* After Biopsy Section End */}

      {/* Living With Biopsy Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={recoveringFromKidneyBiopsyRef}
      >
        <AboutSectionStyle3
          title={t("recoveringFromKidneyBiopsy.title")}
          subTitle={t("recoveringFromKidneyBiopsy.description")}
          // customTitle={true}
          // secondTitle={t("understandingYourKidneyBiopsyResults.title")}
          // secondSubTitle={t("understandingYourKidneyBiopsyResults.description")}
          imgUrl="/images/kidney_educations/kidney-biopsy/recovering-from-biopsy.jpg"
          enableBgShadow={false}
        />
      </Section>
      {/* Living With Biopsy Section End */}

      {/* Important Note Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={importantNoteRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("importantNote.title")}
              text={t("importantNote.description")}
            />
          </div>
        </div>
      </Section>
      {/* Important Note Section End */}
      <Spacing md="40" xl="80" lg="100" />
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default KidneyBiopsy;
