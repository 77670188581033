import React, { useRef } from "react";
import Section from "../../../components/Section";
import { pageTitle } from "../../../helpers/PageTitle";
import { Trans, useTranslation } from "react-i18next";
import { avFistulaContentDatas } from "./avFistulaContents";
import AwardSection from "../../../components/Section/AwardSection";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import WorkingProcess from "../../../components/Section/WorkingProcess";
import List from "../../../components/List";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";


const AVFistula = () => {
  pageTitle("AV Fistula");
  const { t } = useTranslation(["avFistula", "common"]);

  const whyAvFistulaRef = useRef(null);
  const whenToConsiderRef = useRef(null);
  const howCreatedRef = useRef(null);
  const rememnberRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Why av fistula",
      btnRef: whyAvFistulaRef,
      btnImage: "/images/kidney_educations/av-fitsula/av-fistula-icon-01.svg",
    },
    {
      btnName: "When to consider",
      btnRef: whenToConsiderRef,
      btnImage: "/images/kidney_educations/av-fitsula/av-fistula-icon-01.svg",
    },
    {
      btnName: "How it's created",
      btnRef: howCreatedRef,
      btnImage: "/images/kidney_educations/av-fitsula/av-fistula-icon-01.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: rememnberRef,
    // },
  ];

  const { whyCreateAVFistulaData, howIsAVFistulaCreatedData, rememberData } =
    avFistulaContentDatas();

  const rememberDiseasesSymptoms = [
    {
      title: `${t("remember.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: <List heading="" description="" data={rememberData} />,
    },
  ];

  const { blogData } = commonConstants();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/av-fitsula/av-fistula-web-banner-new.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/banner_img.png"
        title={t("bannerTitle")}
        subHeadTitle={t("bannerSubTitle")}
        subTitle={t("bannerDescription")}
        customClasses="cs_av_fistula"
        customeThemeClass="cs_kidney_education_color"
      /> */}

      {/* Why AV Fistula Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_av_fistula"
        sectionRef={whyAvFistulaRef}
      >
        <AwardSection
          sectionTitle={t("whyCreateAVFistula.title")}
          sectionSubTitle={`${t("whyCreateAVFistula.description")}:`}
          data={whyCreateAVFistulaData}
        />
      </Section>
      {/* Why AV Fistula Section End */}

      {/* When To Consider Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={whenToConsiderRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("whenConsiderAVFistula.title")}
              text={t("whenConsiderAVFistula.description1")}
              subTitle={
                <h4>
                  <Trans
                    i18nKey={t("whenConsiderAVFistula.description2")}
                    components={{
                      styledFont: <i />,
                    }}
                  />
                </h4>
              }
            />
          </div>
        </div>
      </Section>
      {/* When To Consider Section End */}

      {/* How AV Fistula Created Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_av_fistula cs_white_bg"
        sectionRef={howCreatedRef}
      >
        <WorkingProcess
          sectionTitle={t("howIsAVFistulaCreated.title")}
          sectionSubTitleTans={
            <Trans
              i18nKey={t("howIsAVFistulaCreated.description")}
              components={{
                styledFont: <i />,
                boldText: <b />,
              }}
            />
          }
          sectionTitleUp=""
          sectionTitleDown=""
          data={howIsAVFistulaCreatedData}
        />
      </Section>
      {/* How AV Fistula Created Section End */}

      {/* Remember Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney cs_white_bg"
        sectionRef={rememnberRef}
      >
        <div className="container">
          <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
            <SingleGridAccordion
              accordionData={rememberDiseasesSymptoms}
              customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default AVFistula;
