import React, { useRef } from "react";
import Section from "../../../components/Section";
import { pageTitle } from "../../../helpers/PageTitle";
import Spacing from "../../../components/Spacing";
import { Trans, useTranslation } from "react-i18next";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import { cathetersContentDatas } from "./cathetersContents";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import DepartmentSectionStyle6 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle6";
import WorkingProcess from "../../../components/Section/WorkingProcess";
import AboutSectionStyle3 from "../../../components/Section/AboutSection/AboutSectionStyle3";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import AboutSectionStyle5 from "../../../components/Section/AboutSection/AboutSectionStyle5";
import List from "../../../components/List";
import FeaturesSectionStyle3 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle3";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";


const Catheters = () => {
  pageTitle("Catheters");
  const { t } = useTranslation(["catheters", "common"]);

  const chooseCatheterRef = useRef(null);
  const typesOfCatheterRef = useRef(null);
  const catheterPlacementRef = useRef(null);
  const carryingCatheterRef = useRef(null);
  const livingWithCatheterRef = useRef(null);
  const rememberRef = useRef(null);
  const tunneledVsNonTunneledRef = useRef(null);
  const tunneledVsNonTunneledBenefitsRef = useRef(null);
  const WhenToConsiderRef = useRef(null);
  const makingDecisionRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Choose dialysis catheter",
      btnRef: chooseCatheterRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-01.svg",
    },
    {
      btnName: "Types of catheters",
      btnRef: typesOfCatheterRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-02.svg",
    },
    {
      btnName: "Catheter placement procedures",
      btnRef: catheterPlacementRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-03.svg",
    },
    {
      btnName: "Carrying for a catheter",
      btnRef: carryingCatheterRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-04.svg",
    },
    {
      btnName: "Living with catheter",
      btnRef: livingWithCatheterRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-05.svg",
    },
    // {
    //   btnName: "Remember",
    //   btnRef: rememberRef,
    // },
    {
      btnName: "Tunneled vs Non-tunneled catheters",
      btnRef: tunneledVsNonTunneledRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-06.svg",
    },
    {
      btnName: "Tunneled vs Non-tunneled benefits",
      btnRef: tunneledVsNonTunneledBenefitsRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-07.svg",
    },
    {
      btnName: "When to consider",
      btnRef: WhenToConsiderRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-08.svg",
    },
    {
      btnName: "Making decision",
      btnRef: makingDecisionRef,
      btnImage: "/images/kidney_educations/catheters/catheters-icon-09.svg",
    },
  ];

  const {
    whyChooseDialysisCatheterData,
    typesOfDialysisCathetersData,
    catheterPlacementProcedureData,
    caringForDialysisCatheterData,
    tunneledCatheterAdvantagesData,
    tunneledCatheterDisadvantagesData,
    nonTunneledCatheterAdvantagesData,
    nonTunneledCatheterDisadvantagesData,
    factorsToConsiderWhenChoosingData,
  } = cathetersContentDatas();

  const tunneledCatheterAdvantageList = [
    {
      title: `${t("tunneledCatheterAdvantages.title")}`,
      iconUrl: "/images/home/ok-up-icon.svg",
      content: (
        <List heading="" description="" data={tunneledCatheterAdvantagesData} />
      ),
    },
  ];

  const tunneledCatheterDisAdvantageList = [
    {
      title: `${t("tunneledCatheterDisadvantages.title")}`,
      iconUrl: "/images/home/ok-down-icon.svg",
      content: (
        <List
          heading=""
          description=""
          data={tunneledCatheterDisadvantagesData}
        />
      ),
    },
  ];

  const tunneledCatheterCostList = [
    {
      title: `${t("tunneledCatheterCost.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("tunneledCatheterCost.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const nonTunneledCatheterAdvantageList = [
    {
      title: `${t("nonTunneledCatheterAdvantages.title")}`,
      iconUrl: "/images/home/ok-up-icon.svg",
      content: (
        <List
          heading=""
          description=""
          data={nonTunneledCatheterAdvantagesData}
        />
      ),
    },
  ];

  const nonTunneledCatheterDisAdvantageList = [
    {
      title: `${t("nonTunneledCatheterDisadvantages.title")}`,
      iconUrl: "/images/home/ok-down-icon.svg",
      content: (
        <List
          heading=""
          description=""
          data={nonTunneledCatheterDisadvantagesData}
        />
      ),
    },
  ];

  const nonTunneledCatheterCostList = [
    {
      title: `${t("nonTunneledCatheterCost.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List
          heading=""
          description={`${t("nonTunneledCatheterCost.description")}`}
          data={[]}
        />
      ),
    },
  ];
  const { blogData } = commonConstants();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/catheters/catheters-web-banner-new.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* <Section className="cs_dialysis">
        <BannerSectionStyle3
          bgUrl="/images/appointments/banner_bg.svg"
          imgUrl="/images/appointments/banner_img.png"
          title={t("bannerTitle")}
          subTitle={t("bannerDescription")}
          subHeadTitle={t("bannerSubTitle")}
          customeThemeClass="cs_kidney_education_color"
        />
      </Section> */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* Why Dialysis Catheter Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_catheters"
        sectionRef={chooseCatheterRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("whyChooseDialysisCatheter.title")}
          sectionSubTitle={`${t("whyChooseDialysisCatheter.description")}:`}
          data={whyChooseDialysisCatheterData}
        />
      </Section>
      {/* Why Dialysis Catheter Section End */}

      {/* Types Of Catheter Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_catheters cs_white_bg"
        sectionRef={typesOfCatheterRef}
      >
        <DepartmentSectionStyle6
          sectionTitle={t("typesOfDialysisCatheters.title")}
          sectionSubTitle={`${t("typesOfDialysisCatheters.description")}:`}
          data={typesOfDialysisCathetersData}
        />
      </Section>
      {/* Types Of Catheter Section End */}

      {/* Catheter Placement Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_av_fistula cs_white_bg"
        sectionRef={catheterPlacementRef}
      >
        <WorkingProcess
          sectionTitle={t("catheterPlacementProcedure.title")}
          sectionSubTitle={`${t("catheterPlacementProcedure.description")}:`}
          sectionTitleUp=""
          sectionTitleDown=""
          data={catheterPlacementProcedureData}
        />
      </Section>
      {/* Catheter Placement Section End */}

      {/* Carrying Dialysis Catheter Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={carryingCatheterRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("caringForDialysisCatheter.title")}
          subTitle={`${t("caringForDialysisCatheter.description")}:`}
          data={caringForDialysisCatheterData}
        />
      </Section>
      {/* Carrying Dialysis Catheter Section End */}

      {/* Living With Dialysis Catheter Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg"
        sectionRef={livingWithCatheterRef}
      >
        <AboutSectionStyle3
          title={t("livingWithDialysisCatheter.title")}
          subTitle={t("livingWithDialysisCatheter.description")}
          imgUrl="/images/kidney_educations/catheters/dialysis-catheters-1209.jpg"
          enableBgShadow={false}
        />
      </Section>
      {/* Living With Dialysis Catheter Section End */}

      {/* Remember Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={rememberRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("remember.title")}
              text={t("remember.description")}
            />
          </div>
        </div>
      </Section>
      {/* Remember Section End */}

      {/* Tunneled Vs Non-Tunneled Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={30}
        bottomLg={30}
        bottomXl={30}
        className="cs_transplant"
        sectionRef={tunneledVsNonTunneledRef}
      >
        <AboutSectionStyle5
          imgUrl="/images/kidney_educations/catheters/tunneled-vs-non-new-1209.jpg"
          title={t("tunneledVsNonTunneled.title")}
          subTitle={`${t("tunneledVsNonTunneled.description")}:`}
        />
      </Section>
      {/* Tunneled Vs Non-Tunneled Section End */}

      {/* Tunneled Vs Non-Tunneled Benefits Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={tunneledVsNonTunneledBenefitsRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6 cs_list_sec1">
              <h3>{`${t("tunneledCatheter")}`}</h3>
              <SingleGridAccordion
                accordionData={tunneledCatheterAdvantageList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <List
                heading={`${t("tunneledCatheterAdvantages.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={tunneledCatheterAdvantagesData}
              /> */}
              <Spacing md="30" lg="30" xl="30" />
              <SingleGridAccordion
                accordionData={tunneledCatheterDisAdvantageList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <List
                heading={`${t("tunneledCatheterDisadvantages.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={tunneledCatheterDisadvantagesData}
              /> */}
              <Spacing md="30" lg="30" xl="30" />
              <SingleGridAccordion
                accordionData={tunneledCatheterCostList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <h4>{`${t("tunneledCatheterCost.title")}:`}</h4>
              <p>{`${t("tunneledCatheterCost.description")}`}</p> */}
            </div>
            <div className="col-lg-6 col-xl-6 cs_list_sec2">
              <h3>{`${t("nonTunneledCatheter")}`}</h3>
              <SingleGridAccordion
                accordionData={nonTunneledCatheterAdvantageList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <List
                heading={`${t("nonTunneledCatheterAdvantages.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={nonTunneledCatheterAdvantagesData}
              /> */}
              <Spacing md="30" lg="30" xl="30" />
              <SingleGridAccordion
                accordionData={nonTunneledCatheterDisAdvantageList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <List
                heading={`${t("nonTunneledCatheterDisadvantages.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={nonTunneledCatheterDisadvantagesData}
              /> */}
              <Spacing md="30" lg="30" xl="30" />
              <SingleGridAccordion
                accordionData={nonTunneledCatheterCostList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon"
              />
              {/* <h4>{`${t("nonTunneledCatheterCost.title")}:`}</h4>
              <p>{`${t("nonTunneledCatheterCost.description")}`}</p> */}
            </div>
          </div>
        </div>
      </Section>
      {/* Tunneled Vs Non-Tunneled Benefits Section End */}

      {/* When To Consider Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_dialysis"
        sectionRef={WhenToConsiderRef}
      >
        <FeaturesSectionStyle3
          sectionTitle={t("factorsToConsiderWhenChoosing")}
          data={factorsToConsiderWhenChoosingData}
        />
      </Section>
      {/* When To Consider Section End */}

      {/* Making Decision Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={makingDecisionRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("makingDecision.title")}
              text={t("makingDecision.description1")}
              subTitle={
                <p>
                  <Trans
                    i18nKey={t("makingDecision.description2")}
                    components={{
                      styledFont: <i />,
                    }}
                  />
                </p>
              }
            />
          </div>
        </div>
      </Section>
      {/* Making Decision Section End */}
      <Spacing md="40" xl="80" lg="100" />
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default Catheters;
