import React from "react";
import ContactForm from "../ContactForm";
import FeaturesSectionStyle2 from '../Section/FeaturesSection/FeaturesSectionStyle2'



const PopupPageModalComponent = ({ isOpen, onClose, heading , featureListData, featureImage, featureHeading}) => {
  return (
    <div
      className={`modal  modal-dialog-scrollable modal-lg ${isOpen ? "show" : ""
        }`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content" style={{ marginBottom: 60 }}>
          <div className="modal-header">
            <h5 className="modal-title">{heading || 'Contact Us'}</h5>
            <button type="button" onClick={onClose} className="btn btn-link" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">

            <FeaturesSectionStyle2
              sectionTitle={featureHeading}
              imgUrl={featureImage}
              data={featureListData}
            />

            <ContactForm customStyle={{ formContainer: "pop-up-contact" }} />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {/* {isOpen && <div className="modal-backdrop fade show"></div>} */}
    </div>
  );
};

export default PopupPageModalComponent;
