import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import { useTranslation } from "react-i18next";
import Section from "../../../components/Section";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle4 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle4";
import AwardSection from "../../../components/Section/AwardSection";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import { healthyKidneysContentDatas } from "./healthyKidneysContents";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";
import ImageWithTextOverlaySection from "../../../components/ImageWithTextOverlay/ImageWithTextOverlaySection";
import SectionHeading from "../../../components/SectionHeading";
import Spacing from "../../../components/Spacing";

const HealthyKidneys = () => {
  pageTitle("Healthy Kidneys");
  const { t } = useTranslation(["healthyKidneys", "common"]);

  const mightyMarvelsRef = useRef(null);
  const healthyKidneysRef = useRef(null);
  const simpleStepsRef = useRef(null);
  const infographicIdeasRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Kidneys: The mighty marvels",
      btnRef: mightyMarvelsRef,
      btnImage:
        "/images/kidney_educations/healthy-kidneys/healthy-kidneys-icon-01.svg",
    },
    {
      btnName: "Why healthy kidneys matter",
      btnRef: healthyKidneysRef,
      btnImage:
        "/images/kidney_educations/healthy-kidneys/healthy-kidneys-icon-02.svg",
    },
    {
      btnName: "Steps for healthy kidneys",
      btnRef: simpleStepsRef,
      btnImage:
        "/images/kidney_educations/healthy-kidneys/healthy-kidneys-icon-03.svg",
    },
    {
      btnName: "Kidney infographics",
      btnRef: infographicIdeasRef,
      btnImage:
        "/images/kidney_educations/healthy-kidneys/healthy-kidneys-icon-04.svg",
    },
  ];


  const { blogData } = commonConstants();
  const {
    mightyMarvelsData,
    whyHealthyKidneysData,
    simpleStepsData,
    blogInfoGrapics,
  } = healthyKidneysContentDatas();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/healthy-kidneys/healthy-kidney-web-banner.png"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription.line1")}
          subTitle2={t("bannerDescription.line2")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* Mighty Marvel Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_healthy_kidneys"
        sectionRef={mightyMarvelsRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("theMightyMarvels.title")}
          sectionSubTitle={`${t("theMightyMarvels.description")}:`}
          data={mightyMarvelsData}
        />
      </Section>
      {/* Mighty Marvel Section End */}

      {/* Why Healthy Kidney Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg"
        sectionRef={healthyKidneysRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("whyHealthyKidneysMatter.title")}
          subTitle={`${t("whyHealthyKidneysMatter.description")}:`}
          data={whyHealthyKidneysData}
        />
      </Section>
      {/* Why Healthy Kidney Section End */}

      {/* Simple Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={simpleStepsRef}
        className="cs_healthy_kidneys"
      >
        <FeaturesSectionStyle4
          sectionTitle={t("simpleStepsForStellarKidneyHealth.title")}
          sectionSubTitle={`${t(
            "simpleStepsForStellarKidneyHealth.description"
          )}:`}
          data={simpleStepsData}
        />
      </Section>
      {/* Simple Section End */}

      {/* Infographic Ideas Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling_img_txt_sec"
      >
        <SectionHeading
          title={t("infographicIdeas.title")}
          subTitle={t("infographicIdeas.description")}
          center
        />
        <Spacing md="80" lg="80" />
        <ImageWithTextOverlaySection
          showTint={false}
          imageWithTextData={blogInfoGrapics}
        />
      </Section>
      {/* Infographic Ideas Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default HealthyKidneys;
