import React, { useRef } from "react";
import Section from "../../../components/Section";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import { useTranslation } from "react-i18next";
import { pageTitle } from "../../../helpers/PageTitle";
import ScrollToSection from "../../../components/ScrollToSection";
import { counsellingContentDatas } from "./counsellingContent";
import List from "../../../components/List";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import Spacing from "../../../components/Spacing";
import WorkingProcess from "../../../components/Section/WorkingProcess";
import SectionHeading from "../../../components/SectionHeading";
import BlogSectionStyle5 from "../../../components/Section/BlogSection/BlogSectionStyle5";
import ImageWithTextOverlaySection from "../../../components/ImageWithTextOverlay/ImageWithTextOverlaySection";
import FaqSection from "../../../components/Section/FaqSection";
import { blogDatas } from "../../../constants/common-contents";
import AwardWithAccordionSection from "../../../components/Section/AwardSection/AwardWithAccordionSection";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";
import MultiGridAccordian from "../../../components/MultiGridAccordian";

export const CounsellingNew = () => {
  pageTitle("Counselling");
  const { t } = useTranslation(["counselling", "common"]);

  const kidneyDiseaseEducationRef = useRef(null);
  const medicationManagementRef = useRef(null);
  const emotinoalSupportRef = useRef(null);
  const healthyLifestyleRef = useRef(null);
  const elderlyCareCounsellingRef = useRef(null);
  const eskdCkdSupportRef = useRef(null);
  const vascularAccessRef = useRef(null);
  const dialysisSupportRef = useRef(null);
  const transplantJourneyRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Kidney Disease Counselling",
      btnRef: kidneyDiseaseEducationRef,
      btnImage: "/images/our_services/counselling/counselling-icon-01.svg",
    },
    {
      btnName: "Medication Management",
      btnRef: medicationManagementRef,
      btnImage: "/images/our_services/counselling/counselling-icon-02.svg",
    },
    {
      btnName: "Emotional Support",
      btnRef: emotinoalSupportRef,
      btnImage: "/images/our_services/counselling/counselling-icon-03.svg",
    },
    {
      btnName: "Motivation and Self Care",
      btnRef: healthyLifestyleRef,
      btnImage: "/images/our_services/counselling/counselling-icon-04.svg",
    },
    {
      btnName: "Elderly Kidney Care & Specialized Counseling",
      btnRef: elderlyCareCounsellingRef,
      btnImage: "/images/our_services/counselling/counselling-icon-06.svg",
    },
    {
      btnName: "ESKD & Advanced CKD Support",
      btnRef: eskdCkdSupportRef,
      btnImage: "/images/our_services/counselling/counselling-icon-05.svg",
    },
    {
      btnName: "AV Fistula & Catheter Guidance & Care",
      btnRef: vascularAccessRef,
      btnImage: "/images/our_services/counselling/counselling-icon-07.svg",
    },
    {
      btnName: "Dialysis Support & Counselling",
      btnRef: dialysisSupportRef,
      btnImage: "/images/our_services/counselling/counselling-icon-08.svg",
    },
    {
      btnName: "Transplant support & Counselling",
      btnRef: transplantJourneyRef,
      btnImage: "/images/our_services/counselling/counselling-icon-09.svg",
    },
  ];

  const {
    kidneyDiseaseCounsellingDatas,
    specializedSupportForSeniorsData,
    ourServiceBlogSectionDatas1,
    kidneyDiseaseCounsellingListData,
    medicalManagementListData,
    emotionalWellBeingListData,
  } = counsellingContentDatas();

  const { blogData } = commonConstants();

  const kidneyDiseaseCounsellingList1 = [
    {
      title: `${t("kidneyDiseaseCounsellingList.title")}`,
      content: (
        <List
          heading=""
          description={`${t("kidneyDiseaseCounselling.description")}:`}
          data={kidneyDiseaseCounsellingListData}
        />
      ),
    },
  ];

  const medicationManagementList1 = [
    {
      title: `${t("medicationManagement.title")}`,
      content: (
        <List
          heading=""
          description={`${t("medicationManagement.description")}:`}
          data={medicalManagementListData}
        />
      ),
    },
  ];

  const emotionalWellBeingList = [
    {
      title: `${t("emotionalWellBeing.title")}`,
      content: (
        <List
          heading=""
          description={`${t("emotionalWellBeing.description")}:`}
          data={emotionalWellBeingListData}
        />
      ),
    },
  ];

  const motivationSelfCareList = [
    {
      title: `${t("motivationSelfCare.title")}`,
      content: (
        <List
          heading=""
          description={`${t("motivationSelfCare.description")}:`}
          data={emotionalWellBeingListData}
        />
      ),
    },
  ];

  const cardWithAccordianDatas = {
    0: {
      classes: "cs_accordians_our_services cs_accordians_with_icon",
      data: kidneyDiseaseCounsellingList1,
    },
    1: {
      classes: "cs_accordians_our_services cs_accordians_with_icon",
      data: medicationManagementList1,
    },
    2: {
      classes: "cs_accordians_our_services cs_accordians_with_icon",
      data: emotionalWellBeingList,
    },
    3: {
      classes: "cs_accordians_our_services cs_accordians_with_icon",
      data: motivationSelfCareList,
    },
  };

  const specializedCounsellingServiceList1 = [
    {
      title: `${t("specializedCounsellingServiceList.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "specializedCounsellingServiceList.lists.list1.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const specializedCounsellingServiceList2 = [
    {
      title: `${t("specializedCounsellingServiceList.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "specializedCounsellingServiceList.lists.list2.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const specializedCounsellingServiceList3 = [
    {
      title: `${t("specializedCounsellingServiceList.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "specializedCounsellingServiceList.lists.list3.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const specializedCounsellingServiceList4 = [
    {
      title: `${t("specializedCounsellingServiceList.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "specializedCounsellingServiceList.lists.list4.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const eskdCkdSupportList1 = [
    {
      title: `${t("eskdCkdSupportList.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("eskdCkdSupportList.lists.list1.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const eskdCkdSupportList2 = [
    {
      title: `${t("eskdCkdSupportList.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("eskdCkdSupportList.lists.list2.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const eskdCkdSupportList3 = [
    {
      title: `${t("eskdCkdSupportList.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("eskdCkdSupportList.lists.list3.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const eskdCkdSupportList4 = [
    {
      title: `${t("eskdCkdSupportList.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("eskdCkdSupportList.lists.list4.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const vascularAccessGuidanceList1 = [
    {
      title: `${t("vascularAccessGuidanceList.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "vascularAccessGuidanceList.lists.list1.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const vascularAccessGuidanceList2 = [
    {
      title: `${t("vascularAccessGuidanceList.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "vascularAccessGuidanceList.lists.list2.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const vascularAccessGuidanceList3 = [
    {
      title: `${t("vascularAccessGuidanceList.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "vascularAccessGuidanceList.lists.list3.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const vascularAccessGuidanceList4 = [
    {
      title: `${t("vascularAccessGuidanceList.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "vascularAccessGuidanceList.lists.list4.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const dialysisSupportList1 = [
    {
      title: `${t("dialysisSupportList.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("dialysisSupportList.lists.list1.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const dialysisSupportList2 = [
    {
      title: `${t("dialysisSupportList.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("dialysisSupportList.lists.list2.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const dialysisSupportList3 = [
    {
      title: `${t("dialysisSupportList.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("dialysisSupportList.lists.list3.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const dialysisSupportList4 = [
    {
      title: `${t("dialysisSupportList.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("dialysisSupportList.lists.list4.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const transplantJourneyList1 = [
    {
      title: `${t("transplantJourneyList.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("transplantJourneyList.lists.list1.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const transplantJourneyList2 = [
    {
      title: `${t("transplantJourneyList.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("transplantJourneyList.lists.list2.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const transplantJourneyList3 = [
    {
      title: `${t("transplantJourneyList.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("transplantJourneyList.lists.list3.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const transplantJourneyList4 = [
    {
      title: `${t("transplantJourneyList.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("transplantJourneyList.lists.list4.description")}`}
          data={[]}
        />
      ),
    },
  ];

  const remember1List = [
    {
      title: `${t("remember1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t("remember1.description")}`}
          data={[]}
        />
      ),
    },
  ];

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/our_services/counselling/counselling-web-banner-new.jpg"
          // imgUrl="/images/our_services/counselling/counselling-web-banner-new-size.jpg"
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <div className="container">
          <h2>{t("section_1_title")}</h2>
        </div>
        <ScrollToSection btnLists={scrollToLists} brandName="our-services" />
      </Section>
      {/* Section Navigation End */}

      {/* Kidney Disease Counselling Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling cs_counselling_iconbox_style1"
        sectionRef={kidneyDiseaseEducationRef}
      >
        <AwardWithAccordionSection
          sectionTitle={t("kidneyDiseaseCounselling.title")}
          sectionSubTitle={t("kidneyDiseaseCounselling.description")}
          data={kidneyDiseaseCounsellingDatas}
          accordionInfos={cardWithAccordianDatas}
        />
      </Section>
      {/* Kidney Disease Counselling Section End */}

      {/* Specialized Support Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_service-catheters cs_white_bg"
        // sectionRef={elderlyCareCounsellingRef}
      >
        <WorkingProcess
          sectionTitle={t("specializedSupportForSeniors.title")}
          sectionSubTitle={`${t("specializedSupportForSeniors.description")}:`}
          sectionTitleUp=""
          sectionTitleDown=""
          data={specializedSupportForSeniorsData}
        />
      </Section>
      {/* Specialized Support Section End */}

      {/* Specialized Counseling Services Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={elderlyCareCounsellingRef}
      >
        <div className="container">
          <SectionHeading
            title={t("specializedCounsellingService.title")}
            subTitle={`${t("specializedCounsellingService.description")}`}
            variantColor="cs_white_color"
            center
          />
          <Spacing lg="30" md="30" />
        </div>
        <MultiGridAccordian
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          leftSideDatas={[
            specializedCounsellingServiceList1,
            specializedCounsellingServiceList3,
          ]}
          rightSideDatas={[
            specializedCounsellingServiceList2,
            specializedCounsellingServiceList4,
          ]}
          customClasses="cs_accordians_our_services cs_accordians_with_icon"
        />
      </Section>
      {/* Specialized Counseling Services Section End */}

      {/* ESKD & Advanced CKD Support Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={eskdCkdSupportRef}
      >
        <div className="container">
          <SectionHeading
            title={t("eskdCkdSupport.title")}
            subTitle={`${t("eskdCkdSupport.description")}`}
            variantColor="cs_white_color"
            center
          />
          <Spacing lg="30" md="30" />
        </div>
        <MultiGridAccordian
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          leftSideDatas={[eskdCkdSupportList1, eskdCkdSupportList2]}
          rightSideDatas={[eskdCkdSupportList3, eskdCkdSupportList4]}
          customClasses="cs_accordians_our_services cs_accordians_with_icon"
        />
      </Section>
      {/* ESKD & Advanced CKD Support Section End */}

      {/* Vascular Access Guidance Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={vascularAccessRef}
      >
        <div className="container">
          <SectionHeading
            title={t("vascularAccessGuidance.title")}
            subTitle={`${t("vascularAccessGuidance.description")}`}
            variantColor="cs_white_color"
            center
          />
          <Spacing lg="30" md="30" />
        </div>
        <MultiGridAccordian
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          leftSideDatas={[
            vascularAccessGuidanceList1,
            vascularAccessGuidanceList2,
          ]}
          rightSideDatas={[
            vascularAccessGuidanceList3,
            vascularAccessGuidanceList4,
          ]}
          customClasses="cs_accordians_our_services cs_accordians_with_icon"
        />
      </Section>
      {/* Vascular Access Guidance Section End */}

      {/* Dialysis Support Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={dialysisSupportRef}
      >
        <div className="container">
          <SectionHeading
            title={t("dialysisSupport.title")}
            subTitle={`${t("dialysisSupport.description")}`}
            variantColor="cs_white_color"
            center
          />
          <Spacing lg="30" md="30" />
        </div>
        <MultiGridAccordian
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          leftSideDatas={[dialysisSupportList1, dialysisSupportList2]}
          rightSideDatas={[dialysisSupportList3, dialysisSupportList4]}
          customClasses="cs_accordians_our_services cs_accordians_with_icon"
        />
      </Section>
      {/* Dialysis Support Section End */}

      {/* Transplant Journey Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling"
        sectionRef={transplantJourneyRef}
      >
        <div className="container">
          <SectionHeading
            title={t("transplantJourney.title")}
            subTitle={`${t("transplantJourney.description")}`}
            variantColor="cs_white_color"
            center
          />
          <Spacing lg="30" md="30" />
        </div>
        <MultiGridAccordian
          bgUrl="/images/home/dialysis-two-types-images.jpg"
          headingTitle=""
          headingSubTitle=""
          leftSideDatas={[transplantJourneyList1, transplantJourneyList2]}
          rightSideDatas={[transplantJourneyList3, transplantJourneyList4]}
          customClasses="cs_accordians_our_services cs_accordians_with_icon"
        />
      </Section>
      {/* Transplant Journey Section End */}

      {/* Remember Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_about_kidney"
        // sectionRef={remember1Ref}
      >
        <div className="cs_accordion_ref">
          <SingleGridAccordion
            accordionData={remember1List}
            customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordians_only_title"
          />
        </div>
      </Section>
      {/* Remember Section End */}

      {/* Specialized Counseling Services Blog Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_counselling_img_txt_sec"
      >
        <SectionHeading
          title={t("ourSpecializedCounsellingServiceBlog.title")}
          center
        />
        <Spacing md="80" lg="80" />
        <ImageWithTextOverlaySection
          imageWithTextData={ourServiceBlogSectionDatas1}
        />
      </Section>
      {/* Specialized Counseling Services Blog Section End */}

      {/* Custom Blog Section Start */}
      {/* <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection
          sectionTitle=""
          sectionTitleUp=""
          data={blogSectionDatas2}
        />
      </Section> */}
      {/* Cusomt Blog Section End */}

      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection
          customeThemeClass={"bright-blogs"}
          sectionTitle=""
          sectionTitleUp=""
          data={blogData}
        />
      </Section>

      {/* Blog Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSectionStyle5
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          sectionTitleDown=""
          sectionSubTitle=""
          data={blogDatas().slice(0, 3)}
          customClasses="cs_our_service_blogs"
          customThemeClasses="cs_our_services_color"
        />
      </Section>
      {/* Blog Section End */}

      {/* FAQ Section Start */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <FaqSection
          data={t("faq")}
          sectionTitle="Frequently Asked Questions"
          sectionTitleUp=""
          customThemeClasses="cs_faq_accordians_our_services"
        />
      </Section>
      {/* FAQ Section End */}
    </>
  );
};
