import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import Section from "../../../components/Section";
import { Trans, useTranslation } from "react-i18next";
import { transplantContentDatas } from "./transplantContents";
import DepartmentSectionStyle2 from "../../../components/Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import AwardSection from "../../../components/Section/AwardSection";
import BannerSectionStyle2 from "../../../components/Section/BannerSection/BannerSectionStyle2";
import AboutSectionStyle4 from "../../../components/Section/AboutSection/AboutSectionStyle4";
import TestimonialStyle2 from "../../../components/Testimonial/TestimonialStyle2";
import Banner from "../../../components/Section/BannerSection";
import AboutSectionStyle5 from "../../../components/Section/AboutSection/AboutSectionStyle5";
import List from "../../../components/List";
import Cta from "../../../components/Cta";
import FeaturesSectionStyle2 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle2";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";

const Transplant = () => {
  pageTitle("Transplant");
  const { t } = useTranslation(["transplant", "common"]);

  const whatIsKidneyRef = useRef(null);
  const kidneyBenefitsRef = useRef(null);
  const evalutionProcessRef = useRef(null);
  const findingDonorRef = useRef(null);
  const livingWithTransplantRef = useRef(null);
  const importantNoteRef = useRef(null);
  const empoweringRef = useRef(null);
  const understandingNottoSottoRef = useRef(null);
  const nottosottoFunctionRef = useRef(null);
  const findingInformationRef = useRef(null);
  const beTheLightRef = useRef(null);
  const organDonorRef = useRef(null);
  const lastingDiffRef = useRef(null);
  const hintsRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "What is a kidney Transplant?",
      btnRef: whatIsKidneyRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-01.svg",
    },
    {
      btnName: "Benefits of Kidney Transplantation",
      btnRef: kidneyBenefitsRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-02.svg",
    },
    {
      btnName: "Evalution process",
      btnRef: evalutionProcessRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-03.svg",
    },
    {
      btnName: "Finding a donor",
      btnRef: findingDonorRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-04.svg",
    },
    {
      btnName: "Living with transplant",
      btnRef: livingWithTransplantRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-05.svg",
    },
    // {
    //   btnName: "Important Note",
    //   btnRef: importantNoteRef,
    // },
    {
      btnName: "Empowering journey",
      btnRef: empoweringRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-06.svg",
    },
    {
      btnName: "Understanding NOTTO & SOTTO",
      btnRef: understandingNottoSottoRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-07.svg",
    },
    {
      btnName: "NOTTO & SOTTO function",
      btnRef: nottosottoFunctionRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-08.svg",
    },
    {
      btnName: "Finding information",
      btnRef: findingInformationRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-09.svg",
    },
    {
      btnName: "Be the light",
      btnRef: beTheLightRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-10.svg",
    },
    {
      btnName: "Becoming an organ donor",
      btnRef: organDonorRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-11.svg",
    },
    {
      btnName: "Lasting difference",
      btnRef: lastingDiffRef,
      btnImage: "/images/kidney_educations/transplant/transplan-icon-12.svg",
    },
    // {
    //   btnName: "Hints",
    //   btnRef: hintsRef,
    // },
  ];

  const {
    whatIsKidneyTransplantData,
    benefitsOfKidneyTransplantData,
    evaluationProcessForKidneyTransplantData,
    nottoOrganResponsibilitiesData,
    sottoOrganResponsibilitiesData,
    whyBecomeAnOrganDonorData,
    becomeAnOrganDonorTodayData,
  } = transplantContentDatas();

  const nottoOrganResponsibilitiesList = [
    {
      title: `${t("nottoOrganResponsibilities.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List heading="" description="" data={nottoOrganResponsibilitiesData} />
      ),
    },
  ];

  const sottoOrganResponsibilitiesList = [
    {
      title: `${t("sottoOrganResponsibilities.title")}`,
      iconUrl: "/images/home/remember-icon.svg",
      content: (
        <List heading="" description="" data={sottoOrganResponsibilitiesData} />
      ),
    },
  ];

  const { blogData } = commonConstants();

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/kidney_educations/transplant/transplant-web-banner-new.jpg"
          imgUrl=""
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* <Section className="cs_transplant">
        <BannerSectionStyle5
          bgUrl="/images/doctors/banner_bg.svg"
          imgUrl="/images/doctors/banner_img.png"
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass="cs_kidney_education_color"
        />
      </Section> */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
      >
        <ScrollToSection
          btnLists={scrollToLists}
          brandName="kidney-education"
        />
      </Section>
      {/* Section Navigation End */}

      {/* What is Kidney Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        sectionRef={whatIsKidneyRef}
      >
        <DepartmentSectionStyle2
          sectionTitle={t("whatIsKidneyTransplant.title")}
          subTitle={`${t("whatIsKidneyTransplant.description")}:`}
          data={whatIsKidneyTransplantData}
        />
      </Section>
      {/* What is Kidney Section End */}

      {/* Benefits Of Kidney Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={kidneyBenefitsRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("benefitsOfKidneyTransplant.title")}
          sectionSubTitle={`${t("benefitsOfKidneyTransplant.description")}:`}
          data={benefitsOfKidneyTransplantData}
        />
      </Section>
      {/* Benefits Of Kidney Section End */}

      {/* Evaluation Process Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={evalutionProcessRef}
      >
        <AwardSection
          sectionTitle={t("evaluationProcessForKidneyTransplant.title")}
          sectionSubTitle={`${t(
            "evaluationProcessForKidneyTransplant.description"
          )}:`}
          data={evaluationProcessForKidneyTransplantData}
        />
      </Section>
      {/* Evaluation Process Section End */}

      {/* Finding Donor Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={findingDonorRef}
      >
        <BannerSectionStyle2
          bgUrl="/images/kidney_educations/transplant/finding-donor-new.jpg"
          title={t("findingDonor.title")}
          subTitle={t("findingDonor.description")}
        />
      </Section>
      {/* Finding Donor Section End */}

      {/* Living With Transplant Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg"
        sectionRef={livingWithTransplantRef}
      >
        <AboutSectionStyle4
          // imgUrl="/images/home_3/about1.png"
          imgUrl="/images/kidney_educations/transplant/living-with-transplant.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title={t("livingWithTransplantedKidney.title")}
          subTitle={t("livingWithTransplantedKidney.description")}
        />
      </Section>
      {/* Living With Transplant Section End */}

      {/* Important Note Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={importantNoteRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("importantNote.title")}
              text={t("importantNote.description")}
            />
          </div>
        </div>
      </Section>
      {/* Important Note Section End */}

      {/* Empowering Journey Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={empoweringRef}
      >
        <Banner
          // bgUrl="/images/home_1/cta_bg.svg"
          bgUrl="/images/home/kidney-education-bg.png"
          imgUrl="/images/home_1/cta_img.png"
          title={t("empoweringYourJourney.title")}
          subTitle={t("empoweringYourJourney.description")}
        />
      </Section>
      {/* Empowering Journey Section End */}

      {/* Understatnding NOTTO Section Start */}
      <Section
        bottomMd={30}
        bottomLg={30}
        bottomXl={30}
        className="cs_transplant"
        sectionRef={understandingNottoSottoRef}
      >
        <AboutSectionStyle5
          // imgUrl="/images/home_4/about_img.jpeg"
          imgUrl="/images/kidney_educations/transplant/notto-sotto-new-1209.jpg"
          title={t("understandingNottoSotto.title")}
          subTitle={t("understandingNottoSotto.description")}
        />
      </Section>
      {/* Understatnding NOTTO Section End */}

      {/* Understatnding NOTTO Type Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={nottosottoFunctionRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-xl-6 cs_list_sec1">
              <h3>{`${t("nottoOrgan")}`}</h3>
              <h4>{`${t("nottoOrganFunction.title")}:`}</h4>
              <p>{`${t("nottoOrganFunction.description")}`}</p>
              <SingleGridAccordion
                accordionData={nottoOrganResponsibilitiesList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
              {/* <List
                heading={`${t("nottoOrganResponsibilities.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={nottoOrganResponsibilitiesData}
              /> */}
            </div>
            <div className="col-lg-6 col-xl-6 cs_list_sec2">
              <h3>{`${t("sottoOrgan")}`}</h3>
              <h4>{`${t("sottoOrganFunction.title")}:`}</h4>
              <p>{`${t("sottoOrganFunction.description")}`}</p>
              {/* <List
                heading={`${t("sottoOrganResponsibilities.title")}:`}
                iconUrl="/images/icons/category.svg"
                data={sottoOrganResponsibilitiesData}
              /> */}
              <SingleGridAccordion
                accordionData={sottoOrganResponsibilitiesList}
                customClasses="cs_accordians_kidney_education cs_accordians_with_icon cs_accordians_only_title"
              />
            </div>
          </div>
        </div>
      </Section>
      {/* Understatnding NOTTO Type Section End */}

      {/* Finding Information Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_kideny_education cs_important_remember_bg"
        sectionRef={findingInformationRef}
      >
        <div className="container">
          <div className="row">
            <TestimonialStyle2
              title={t("findingInformation.title")}
              text={
                <>
                  <Trans
                    i18nKey={t("findingInformation.description")}
                    components={{
                      aText: (
                        // eslint-disable-next-line jsx-a11y/anchor-has-content
                        <a
                          href="https://notto.mohfw.gov.in/"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        />
                      ),
                      styledQ: <q />,
                    }}
                  />
                </>
              }
            />
          </div>
        </div>
      </Section>
      {/* Finding Information Section End */}

      {/* Be The Light Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_transplant"
        sectionRef={beTheLightRef}
      >
        <Cta
          title={t("becomeAnOrganDonor.title")}
          sectionSubTitle={t("becomeAnOrganDonor.subTitle")}
          subTitle={t("becomeAnOrganDonor.description")}
          bgUrl="/images/kidney_educations/transplant/be-the-light-organ-donar.jpg"
        />
      </Section>
      {/* Be The Light Section End */}

      {/* Becoming Organ Donor Section Start */}
      <Section
        bottomMd={100}
        bottomLg={110}
        className="cs_white_bg cs_transplant"
        sectionRef={organDonorRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("whyBecomeAnOrganDonor.title")}
          data={whyBecomeAnOrganDonorData}
        />
      </Section>
      {/* Becoming Organ Donor Section End */}

      {/* Lasting Difference Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_transplant"
        sectionRef={lastingDiffRef}
      >
        <FeaturesSectionStyle2
          sectionTitle={t("becomeAnOrganDonorToday.title")}
          sectionTitleDown={`${t("becomeAnOrganDonorToday.subTitle")}:`}
          // imgUrl="/images/about/why_choose_us.jpeg"
          imgUrl="/images/kidney_educations/transplant/lasting-difference-new-1209.jpg"
          data={becomeAnOrganDonorTodayData}
          customThemeClasses="cs_kidney_education_color"
        />
      </Section>
      {/* Lasting Difference Section End */}

      {/* Hints Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_transplant cs_white_bg"
        sectionRef={hintsRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="cs_blog_details col-lg-8">
              <blockquote>
                <p>{`${t("someoneHopeFade")}`}</p>
              </blockquote>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </Section>
      {/* Hints Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default Transplant;
