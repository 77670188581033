import React, { useRef } from "react";
import { pageTitle } from "../../../helpers/PageTitle";
import { useTranslation } from "react-i18next";
import { healthRecordsContentDatas } from "./healthRecordsContents";
import Section from "../../../components/Section";
import SectionHeading from "../../../components/SectionHeading";
import Spacing from "../../../components/Spacing";
import List from "../../../components/List";
import FeaturesSectionStyle5 from "../../../components/Section/FeaturesSection/FeaturesSectionStyle5";
import BannerSectionStyle6 from "../../../components/Section/BannerSection/BannerSectionStyle6";
import KidneyEducationBannerStyle from "../../../components/Section/BannerSection/KidneyEducationBannerStyle";
import KidneyBreadcrumStyle from "../../../components/Breadcrumb/KidneyBreadcrumStyle";
import ScrollToSection from "../../../components/ScrollToSection";
import SingleGridAccordion from "../../../components/SingleGridAccordion/SingleGridAccordion";
import BlogSection from "../../../components/Section/BlogSection";
import { commonConstants } from "../../common/commonConstants";

const HealthRecords = () => {
  pageTitle("Health Records");
  const { t } = useTranslation(["healthRecords", "common"]);

  const manageHealthRecordsRef = useRef(null);
  const benefitsRef = useRef(null);
  const partnerwithUsTodayRef = useRef(null);

  const scrollToLists = [
    {
      btnName: "Manage your health records",
      btnRef: manageHealthRecordsRef,
      btnImage:
        "/images/our_services/health-records/health-records-icon-01.svg",
    },
    {
      btnName: "Benefits",
      btnRef: benefitsRef,
      btnImage:
        "/images/our_services/health-records/health-records-icon-02.svg",
    },
    {
      btnName: "Partner with us today",
      btnRef: partnerwithUsTodayRef,
      btnImage:
        "/images/our_services/health-records/health-records-icon-03.svg",
    },
  ];

  const {
    whyManageYourHealthRecordsData2,
    whyManageYourHealthRecordsData3,
    benefitsOfManagingYourHealthRecordsData,
  } = healthRecordsContentDatas();

  const { blogData } = commonConstants();

  const manageHealthRecordsList1 = [
    {
      title: `${t("whyManageYourHealthRecords.lists.list1.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "whyManageYourHealthRecords.lists.list1.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const manageHealthRecordsList2 = [
    {
      title: `${t("whyManageYourHealthRecords.lists.list2.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "whyManageYourHealthRecords.lists.list2.description"
          )}:`}
          data={whyManageYourHealthRecordsData2}
        />
      ),
    },
  ];

  const manageHealthRecordsList3 = [
    {
      title: `${t("whyManageYourHealthRecords.lists.list3.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description=""
          data={whyManageYourHealthRecordsData3}
        />
      ),
    },
  ];

  const manageHealthRecordsList4 = [
    {
      title: `${t("whyManageYourHealthRecords.lists.list4.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "whyManageYourHealthRecords.lists.list4.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  const manageHealthRecordsList5 = [
    {
      title: `${t("whyManageYourHealthRecords.lists.list5.title")}`,
      iconUrl: "/images/home/remember-icon-our-services.svg",
      content: (
        <List
          heading=""
          description={`${t(
            "whyManageYourHealthRecords.lists.list5.description"
          )}`}
          data={[]}
        />
      ),
    },
  ];

  return (
    <>
      {/* Breadcrumb Section Start */}
      <Section className="cs_kideny_education">
        <KidneyBreadcrumStyle />
      </Section>
      {/* Breadcrumb Section End */}

      {/* Banner Section Start */}
      {/* <Hero
        title={t("bannerTitle")}
        subHeadTitle={t("bannerSubTitle")}
        subTitle={t("bannerDescription")}
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img1.png"
        customClasses="cs_healthy_records"
        customeThemeClass="cs_our_services_color"
      /> */}
      <Section className="cs_banner_styles">
        <KidneyEducationBannerStyle
          bgUrl="/images/our_services/health-records/health-records-web-banner-with-gradient.jpg"
          // bgUrl="/images/home/our-service-web-banner-gradient.png"
          // imgUrl="/images/our_services/health-records/health-records-web-banner.jpg"
          title={t("bannerTitle")}
          subHeadTitle={t("bannerSubTitle")}
          subTitle={t("bannerDescription")}
          customeThemeClass=""
        />
      </Section>
      {/* Banner Section End */}

      {/* Section Navigation Start */}
      <Section
        topMd={50}
        topLg={50}
        topXl={50}
        bottomXl={80}
        bottomLg={80}
        bottomMd={80}
        className="cs_white_bg"
      >
        <ScrollToSection btnLists={scrollToLists} brandName="our-services" />
      </Section>
      {/* Section Navigation End */}

      {/* Manage Your Health Records Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_lab_test cs_white_bg"
        sectionRef={manageHealthRecordsRef}
      >
        <div className="container">
          <div className="cs_departments cs_style_1">
            <div
              className="cs_departments_bg cs_radius_25"
              style={{
                backgroundImage: `url(/images/home/our-services-bg.svg)`,
              }}
            />
            <SectionHeading
              title={t("whyManageYourHealthRecords.title")}
              center
            />
            <Spacing md="72" lg="50" />
            <div className="cs_department_list">
              <div className="cs_banner cs_style_9 cs_white_bg cs_radius_30">
                <div className="row">
                  <div className="col-lg-12 col-xl-12">
                    <SingleGridAccordion
                      accordionData={manageHealthRecordsList1}
                      customClasses="cs_accordians_our_services cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={manageHealthRecordsList2}
                      customClasses="cs_accordians_our_services cs_accordians_with_icon cs_accordion_spacing"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={manageHealthRecordsList3}
                      customClasses="cs_accordians_our_services cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={manageHealthRecordsList4}
                      customClasses="cs_accordians_our_services cs_accordians_with_icon"
                    />
                    <Spacing md="20" lg="20" />
                    <SingleGridAccordion
                      accordionData={manageHealthRecordsList5}
                      customClasses="cs_accordians_our_services cs_accordians_with_icon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      {/* Manage Your Health Records Section End */}

      {/* Benefits Of Manage Your Health Records Section Start */}
      <Section
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_white_bg cs_counselling"
        sectionRef={benefitsRef}
      >
        <FeaturesSectionStyle5
          sectionTitle={t("benefitsOfManagingYourHealthRecords.title")}
          data={benefitsOfManagingYourHealthRecordsData}
        />
      </Section>
      {/* Benefits Of Manage Your Health Records Section End */}

      {/* Partner With Us Today Section Start */}
      <Section
        topMd={130}
        topLg={120}
        topXl={110}
        bottomMd={130}
        bottomLg={120}
        bottomXl={110}
        className="cs_healthy_records"
        sectionRef={partnerwithUsTodayRef}
      >
        <BannerSectionStyle6
          imgUrl="/images/departments/banner_img_2_1.png"
          title={t("partnerWithUsToday.title")}
          subTitle={t("partnerWithUsToday.description")}
        />
      </Section>
      {/* Partner With Us Today Section End */}
      <Section bottomMd={130} bottomLg={120} bottomXl={110}>
        <BlogSection customeThemeClass={'bright-blogs'} sectionTitle="" sectionTitleUp="" data={blogData} />
      </Section>
    </>
  );
};

export default HealthRecords;
